<template>
  <div class="custom'material">
    <div :show="loadingMaterial">
      <AudioRecordingModal ref="audio-modal" />
      <QuestionManagerModal
        ref="question-manager"
        :pushedFamilies="pushedFamilies"
        @add-selected-question="pushQuestionMaterial"
        @replace-selected-question="replaceQuestionMaterial"
        @created-question="pushQuestion"
      />
      <b-modal ref="pond-modal-pages" hide-footer hide-header centered>
        <FilePond
          ref="pond-pages"
          class="p-2 m-2"
          label-idle="Arrastra tus archivos acá o haz clic para navegar"
          :allow-multiple="false"
          accepted-file-types="image/jpeg, image/png, image/svg+xml"
          :files="pondObject && pondObject.image ? [pondObject.image] : []"
        />
        <b-row class="text-right">
          <b-col offset="5">
            <b-button pill variant="danger" class="m-1" @click="closePondPages">
              <v-icon name="times" /> Cancelar</b-button
            >
            <b-button
              pill
              variant="primary"
              class="my-1 ml-1 mr-2"
              @click="associateImagePages"
            >
              <v-icon name="save" /> Guardar</b-button
            >
          </b-col>
        </b-row>
      </b-modal>
      <b-modal
        ref="loading-modal"
        centered
        hide-footer
        hide-header
        no-close-on-backdrop
        no-close-on-esc
      >
        <div class="m-2">
          <b-container>
            <b-row>
              <b-col class="text-center">
                <b-spinner
                  style="width: 3rem; height: 3rem"
                  type="grow"
                  label="Loading..."
                ></b-spinner>
              </b-col>
            </b-row>
          </b-container>
        </div>
      </b-modal>
      <b-modal ref="warning-modal" centered hide-footer hide-header rounded-0>
        <div class="m-2">
          <b-row>
            <b-col class="text-center">
              <p>Si vuelve sin guardar se perderán todos los cambios</p>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="text-center">
              <b-button
                class="ml-0"
                pill
                variant="danger"
                @click="$emit('back-to-list')"
              >
                <b>Volver</b>
              </b-button>
            </b-col>
            <b-col class="text-center">
              <b-button
                class="ml-0"
                pill
                variant="primary"
                @click="
                  $refs['warning-modal'].hide();
                  showModalSave();
                "
              >
                <v-icon name="save" /> Guardar
              </b-button>
            </b-col>
          </b-row>
        </div>
      </b-modal>
      <b-modal ref="delete-page-confirmation" centered hide-header hide-footer>
        <b-container>
          <b-row>
            <b-col class="text-center"> ¿Eliminar Página? </b-col>
          </b-row>
          <b-row>
            <b-col class="text-center">
              <b-button
                variant="danger"
                @click="$refs['delete-page-confirmation'].hide()"
                pill
              >
                Cancelar
              </b-button>
            </b-col>
            <b-col class="text-center">
              <b-button variant="warning" @click="deletePage()" pill>
                Eliminar
              </b-button>
            </b-col>
          </b-row>
        </b-container>
      </b-modal>
      <b-container class="w-100 px-0">
        <!-- <b-row class="w-100 mx-auto my-2 p-2">
            <b-col>
              <b-input v-model="material.name" :disabled="!editingFilters">
              </b-input>
            </b-col>
          </b-row> -->
        <div
          v-for="(page, pindex) of material.pages"
          :key="pindex"
          class="my-2 w-100 custom-page px-5"
        >
          <div
            class="page-trash d-flex justify-content-end mb-4"
            style="font-size: 15px; font-family: 'Ubuntu'"
          >
            <div class="text-right m-0 p-0">
              Página {{ pindex + 1 }}

              <b-button
                class="mx-0 btn-circle-sm"
                pill
                size="sm"
                variant="danger"
                v-b-tooltip.hover
                v-if="!material.id || material.is_active"
                title="Borrar pregunta"
                @click="openConfirmation(pindex)"
              >
                <v-icon name="trash-alt" scale="0.8" />
              </b-button>
            </div>
          </div>

          <b-container>
            <div class="d-md-flex justify-content-md-end mb-2">
              <div
                @click="openQuestionListModalPage(pindex, page.text, page.eje)"
                style="color: #fff; font-size: 13px"
                class="btn-xs my-auto custom-button"
              >
                <span style="margin-right: 10px">Añadir preguntas</span
                ><v-icon name="plus" fill="white" scale="0.8" />
              </div>
            </div>
            <div v-if="Boolean(page.text)" class="d-md-flex">
              <div v-if="page.text.content_type === 0">
                <text-component :text="page.text" />
              </div>
              <div v-else-if="page.text.content_type === 1">
                <custom-audio-slider :content="page.text.story_chapters" />
              </div>
            </div>

            <b-row
              v-for="(img, imgI) in page.images"
              :key="imgI + '-img'"
              class="mx-3 my-1 px-0 py-2 image-section rounded border"
            >
              <b-col cols="10" class="border-right">
                <!-- <b-img :src="img.image_url" v-if="img.image.file" width="320"/> -->
                <b-img :src="img.image" width="320" />
              </b-col>
              <b-col
                cols="2"
                class="d-flex justify-content-center align-content-center"
              >
                <b-button
                  class="mx-1"
                  size="md"
                  variant="outline-danger"
                  v-if="!material.id || material.is_active"
                  @click="
                    page.images.splice(imgI, 1);
                    deletedPageImages.push({
                      page: page.id,
                      page_image: img.id,
                    });
                  "
                >
                  <v-icon name="trash-alt" />
                </b-button>
              </b-col>
            </b-row>
            <b-row
              v-for="(question, qindex) of page.questions"
              :key="qindex"
              class="m-2 mx-auto page-form"
            >
              <div class="scissors"></div>
              <b-row v-if="question.subtags.length > 0">
                <b-col
                  v-for="subtag in question.subtags"
                  :key="subtag.id"
                  class="d-block"
                >
                  <div class="tag-badge">
                    <strong>{{ subtag.parent_name }}</strong> -
                    {{ subtag.parent_summary }} <br />
                    <strong> IND: </strong> {{ subtag.summary }}
                  </div>
                </b-col>
              </b-row>
              <b-row v-else-if="question.tag">
                <b-col class="d-block">
                  <div class="tag-badge">
                    <strong>{{ question.tag.name }}</strong> -
                    {{ question.tag.summary }}
                  </div>
                </b-col>
              </b-row>
              <div class="question">
                <b-row>
                  <b-col cols="1">
                    <div class="circle">
                      <p>{{ qindex + 1 }}</p>
                    </div>
                  </b-col>
                  <b-col class="pl-0" cols="9" v-if="!question.id">
                    <b-row class="justify-content-md-center">
                      <b-spinner></b-spinner>
                    </b-row>
                    <b-row class="justify-content-md-center">
                      <span>Generando pregunta</span>
                    </b-row>
                  </b-col>
                  <b-col class="pl-0" cols="9" v-else>
                    <strong>
                      <LatexRenderer v-if="detectLatex(question.question)" :textWithLatex="question.question"/>
                      <p v-else style="white-space: pre-wrap">
                        {{ question.question }}
                      </p>
                    </strong>
                  </b-col>
                  <b-col cols="2" class="float-right">
                    <b-button
                      class="mr-1 btn-circle-sm"
                      pill
                      size="sm"
                      variant="primary"
                      v-b-tooltip.hover
                      v-if="!material.id || material.is_active"
                      title="Editar pregunta"
                      @click="editQuestion(question, pindex, qindex)"
                    >
                      <v-icon name="pencil-alt" />
                    </b-button>
                    <b-button
                      class="mx-0 btn-circle-sm"
                      pill
                      size="sm"
                      variant="danger"
                      v-b-tooltip.hover
                      v-if="!material.id || material.is_active"
                      title="Borrar pregunta"
                      @click="deleteQuestion(pindex, qindex)"
                    >
                      <v-icon name="trash-alt" />
                    </b-button>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col cols="2" class="my-auto text-left">
                    <strong>Puntaje:</strong>
                  </b-col>
                  <b-col
                    cols="2"
                    class="text-left custom-number-input ml-0 pl-0"
                  >
                    <b-input type="number" v-model="question.score" min="0" />
                  </b-col>
                </b-row>
                <div v-if="question.formulas">
                  <b-form-row
                    v-for="(formula, findex) in question.formulas"
                    :key="findex"
                    class="px-3"
                  >
                    <b-col>
                      <math-field
                        @blur="
                          (event) => {
                            change(findex, event.target.value);
                          }
                        "
                        id="formula"
                        ref="formula"
                        style="height: 50px; font-size: 20pt"
                        readonly="true"
                      >
                        {{ formula }}
                      </math-field>
                    </b-col>
                  </b-form-row>
                </div>

                <div v-if="question.audio" class="d-inline-block">
                  <audio
                    :ref="`player-page-${pindex}-question-${qindex}`"
                    :src="question.audio"
                    @ended="
                      () => {
                        nowPlaying = '';
                      }
                    "
                  ></audio>
                  <div
                    v-if="nowPlaying !== `page-${pindex}-question-${qindex}`"
                  >
                    <b-img
                      @click="togglePlayer(`page-${pindex}-question-${qindex}`)"
                      width="30px"
                      style="cursor: pointer"
                      :src="listenIcon"
                    />
                  </div>
                  <div v-else>
                    <b-img
                      @click="togglePlayer(`page-${pindex}-question-${qindex}`)"
                      width="30px"
                      style="cursor: pointer"
                      :src="repeatIcon"
                    />
                    <b-img
                      @click="pausePlayer(`page-${pindex}-question-${qindex}`)"
                      width="30px"
                      style="cursor: pointer"
                      :src="
                        $refs[`player-page-${pindex}-question-${qindex}`] &&
                        $refs[`player-page-${pindex}-question-${qindex}`][
                          'paused'
                        ]
                          ? resumeIcon
                          : pauseIcon
                      "
                    />
                  </div>
                </div>
                <b-row v-if="question.image">
                  <!-- <b-img :src="question.image" width="320px"></b-img> -->
                  <div class="image-container text-center mx-auto my-1">
                    <b-img :src="question.image" width="320px"></b-img>
                    <div class="floating-button-container">
                      <b-button class="floating-button rounded-circle" variant="danger" v-b-tooltip.hover
                      title="Borrar imagen" @click="deleteQuestionImage(question)">
                        <v-icon name="trash-alt" />
                      </b-button>
                      <b-button class="floating-button rounded-circle" variant="primary" v-b-tooltip.hover 
                      title="Regenerar imagen" @click="regenQuestionImage(question)">
                        <v-icon name="pencil-alt" />
                      </b-button>
                    </div>
                  </div>
                </b-row>
              </div>

              <b-col class="" cols="11">
                <div v-if="question.kind === 0" class="question">
                    <b-form-row
                    v-for="(alternative, aindex) of question.alternatives"
                    :key="aindex"
                    class="alternative"
                    :class="{
                      alt_correct: alternative.is_correct,
                      alt_incorrect: !alternative.is_correct,
                    }"
                    >
                    <b-col cols="1">
                      <p>{{ String.fromCharCode(65 + aindex) }})</p>
                    </b-col>
                    <b-col cols="11" v-if="alternative.text">
                      <p v-if="!alternative.is_formula && alternative.text">
                      {{ alternative.text }}
                      </p>
                      <math-field
                        class="align-middle"
                        v-if="alternative.is_formula"
                        :key="alternative.text"
                        style="
                          pointer-events: none;
                          height: 50px;
                          font-size: 20pt;
                          margin-bottom: 10px;
                        "
                        id="formula"
                        :read-only="true"
                      >
                        {{ alternative.text }}
                      </math-field>
                    </b-col>
                    <b-col
                      cols="12"
                      v-if="alternative.audio"
                      class="text-left py-1"
                    >
                      <audio
                        :ref="`player-page-${pindex}-question-${qindex}-alt-${aindex}`"
                        :src="alternative.audio"
                        @ended="
                          () => {
                            nowPlaying = '';
                          }
                        "
                      ></audio>
                      <div
                        v-if="
                          nowPlaying !==
                          `page-${pindex}-question-${qindex}-alt-${aindex}`
                        "
                      >
                        <b-img
                          @click="
                            togglePlayer(
                              `page-${pindex}-question-${qindex}-alt-${aindex}`
                            )
                          "
                          width="25px"
                          style="cursor: pointer"
                          :src="listenIcon"
                        />
                      </div>
                      <div v-else>
                        <b-img
                          @click="
                            togglePlayer(
                              `page-${pindex}-question-${qindex}-alt-${aindex}`
                            )
                          "
                          width="25px"
                          style="cursor: pointer"
                          :src="repeatIcon"
                        />
                        <b-img
                          @click="
                            pausePlayer(
                              `page-${pindex}-question-${qindex}-alt-${aindex}`
                            )
                          "
                          width="25px"
                          style="cursor: pointer"
                          :src="
                            $refs[
                              `player-page-${pindex}-question-${qindex}-alt-${aindex}`
                            ] &&
                            $refs[
                              `player-page-${pindex}-question-${qindex}-alt-${aindex}`
                            ]['paused']
                              ? resumeIcon
                              : pauseIcon
                          "
                        />
                      </div>
                    </b-col>
                    <b-col
                      cols="12"
                      v-if="alternative.image"
                      class="text-left py-1"
                    >
                      <b-img :src="alternative.image" width="320px" />
                      <!-- <v-icon v-if="alternative.image" name="paperclip" class="ml-1 mr-1"/> -->
                    </b-col>
                  </b-form-row>
                </div>

                <div v-if="question.kind === 1" class="question">
                  <b-row class="essay_question">
                    <p v-if="question.response_type === 1">
                      Escribe una respuesta ...
                    </p>
                    <p v-else-if="question.response_type === 2">
                      Adjunta una imagen para responder ...
                    </p>
                    <p v-else-if="question.response_type === 3">
                      Graba un audio con tu respuesta ...
                    </p>
                    <p v-else-if="question.response_type === 4">
                      Responde con una carta ...
                    </p>
                    <p v-else>Responde con una noticia ...</p>
                  </b-row>
                </div>
                <div v-if="question.kind === 2" class="question">
                  <b-row class="dictation_question">
                    <p>
                      Ingresa lo dictado en los
                      {{ question.dictation }} audios ...
                    </p>
                  </b-row>
                </div>
                <div v-if="question.kind === 3" class="question">
                  <b-row>
                    <b-col cols="5" class="options">
                      <div
                        v-for="(option, oindex) of question.options"
                        :key="oindex"
                      >
                        <b-row v-if="option.correct" class="correct-option">
                          <b-col v-if="option.text" cols="12">
                            <p>{{ option.text }}</p>
                          </b-col>
                          <b-col
                            v-if="option.audio"
                            cols="12"
                            class="text-left"
                          >
                            <audio
                              :ref="`player-page-${pindex}-question-${qindex}-opt-${oindex}`"
                              :src="option.audio"
                              @ended="
                                () => {
                                  nowPlaying = '';
                                }
                              "
                            ></audio>
                            <div
                              v-if="
                                nowPlaying !==
                                `page-${pindex}-question-${qindex}-opt-${oindex}`
                              "
                            >
                              <b-img
                                @click="
                                  togglePlayer(
                                    `page-${pindex}-question-${qindex}-opt-${oindex}`
                                  )
                                "
                                width="25px"
                                style="cursor: pointer"
                                :src="listenIcon"
                              />
                            </div>
                            <div v-else>
                              <b-img
                                @click="
                                  togglePlayer(
                                    `page-${pindex}-question-${qindex}-opt-${oindex}`
                                  )
                                "
                                width="25px"
                                style="cursor: pointer"
                                :src="repeatIcon"
                              />
                              <b-img
                                @click="
                                  pausePlayer(
                                    `page-${pindex}-question-${qindex}-opt-${oindex}`
                                  )
                                "
                                width="25px"
                                style="cursor: pointer"
                                :src="
                                  $refs[
                                    `player-page-${pindex}-question-${qindex}-opt-${oindex}`
                                  ] &&
                                  $refs[
                                    `player-page-${pindex}-question-${qindex}-opt-${oindex}`
                                  ]['paused']
                                    ? resumeIcon
                                    : pauseIcon
                                "
                              />
                            </div>
                          </b-col>
                          <b-col
                            v-if="option.image"
                            cols="12"
                            class="text-left"
                          >
                            <b-img :src="option.image" class="w-100" />
                          </b-col>
                        </b-row>
                      </div>
                    </b-col>
                    <b-col cols="5" class="options">
                      <div
                        v-for="(option, oindex) of question.options"
                        :key="oindex"
                      >
                        <b-row v-if="!option.correct" class="incorrect-option">
                          <b-col v-if="option.text" cols="12">
                            <p>{{ option.text }}</p>
                          </b-col>
                          <b-col
                            v-if="option.audio"
                            cols="12"
                            class="text-left"
                          >
                            <audio
                              :ref="`player-page-${pindex}-question-${qindex}-opt-${oindex}`"
                              :src="option.audio"
                              @ended="
                                () => {
                                  nowPlaying = '';
                                }
                              "
                            ></audio>
                            <div
                              v-if="
                                nowPlaying !==
                                `page-${pindex}-question-${qindex}-opt-${oindex}`
                              "
                            >
                              <b-img
                                @click="
                                  togglePlayer(
                                    `page-${pindex}-question-${qindex}-opt-${oindex}`
                                  )
                                "
                                width="25px"
                                style="cursor: pointer"
                                :src="listenIcon"
                              />
                            </div>
                            <div v-else>
                              <b-img
                                @click="
                                  togglePlayer(
                                    `page-${pindex}-question-${qindex}-opt-${oindex}`
                                  )
                                "
                                width="25px"
                                style="cursor: pointer"
                                :src="repeatIcon"
                              />
                              <b-img
                                @click="
                                  pausePlayer(
                                    `page-${pindex}-question-${qindex}-opt-${oindex}`
                                  )
                                "
                                width="25px"
                                style="cursor: pointer"
                                :src="
                                  $refs[
                                    `player-page-${pindex}-question-${qindex}-opt-${oindex}`
                                  ] &&
                                  $refs[
                                    `player-page-${pindex}-question-${qindex}-opt-${oindex}`
                                  ]['paused']
                                    ? resumeIcon
                                    : pauseIcon
                                "
                              />
                            </div>
                          </b-col>
                          <b-col
                            v-if="option.image"
                            cols="12"
                            class="text-left"
                          >
                            <b-img :src="option.image" class="w-100" />
                          </b-col>
                        </b-row>
                      </div>
                    </b-col>
                  </b-row>
                </div>
              </b-col>
              <b-col
                cols="1"
                class="text-center my-auto"
                style="color: #f8b500"
              >
                <table>
                  <tr>
                    <th>
                      <ArrowUpIcon
                        @click.native="moveUp(pindex, qindex)"
                        viewbox="-3 0 60 30"
                        width="35"
                        height="20"
                        :on="qindex !== 0"
                      />
                    </th>
                  </tr>
                  <tr>
                    <th>
                      <small class="text-dark">Mover</small>
                    </th>
                  </tr>
                  <tr>
                    <th>
                      <ArrowDownIcon
                        @click.native="moveDown(pindex, qindex)"
                        viewbox="-3 0 60 30"
                        width="35"
                        height="20"
                        :on="qindex !== page.questions.length - 1"
                      />
                    </th>
                  </tr>
                </table>
              </b-col>
            </b-row>
          </b-container>
        </div>
      </b-container>
    </div>
  </div>
</template>

<script>
import customMaterialService from "@/services/customMaterialService";
import informationService from "@/services/informationService";
import AudioRecordingModal from "@/components/custom-materials/modals/AudioRecordingModal";
import QuestionManagerModal from "@/components/custom-materials/modals/QuestionManagerModal";
import ArrowDownIcon from "../custom-materials/icons/ArrowDownIcon";
import ArrowUpIcon from "../custom-materials/icons/ArrowUpIcon";
import TextComponent from "@/components/botpie-assistant/util-components/TextComponent";
import CustomAudioSlider from "@/components/custom-materials/miscellaneous/CustomAudioSlider";
import assistedMaterialService from "../../services/assistedMaterialService";
import LatexRenderer from "@/components/custom-materials/miscellaneous/LatexRenderer";

export default {
  name: "CreateMaterial",
  components: {
    AudioRecordingModal,
    QuestionManagerModal,
    ArrowDownIcon,
    ArrowUpIcon,
    TextComponent,
    CustomAudioSlider,
    LatexRenderer,
  },
  props: {
    tags: {
      type: Array,
      required: false,
    },
    inMaterial: {
      type: Object,
      required: false,
    },
    mode: {
      type: String,
      required: false,
    },
    context: {
      type: Object,
      required: true,
    },
  },
  watch: {
    material: {
      handler() {
        this.$store.commit("chatbot/updateMessage", {
          name: "material",
          key: "createdMaterial",
          value: this.material,
        });
      },
      deep: true,
    },
    "material.background_image": {
      handler(newVal) {
        console.log("recargando imagen de fondo");
        if (newVal) {
          newVal instanceof File
            ? (this.customBG = URL.createObjectURL(newVal))
            : (this.customBG = newVal);
        }
      },
      deep: true,
    },
  },
  data() {
    return {
      showOptions: true,
      changed: false,
      selectedQuestion: undefined,
      kinds: [
        { text: "Alternativas", value: 0 },
        { text: "Desarrollo", value: 1 },
        { text: "Dictado", value: 2 },
        { text: "Ordenamiento", value: 3 },
        { text: "Múltiples Ejercicios", value: 4 },
      ],
      response_types: [
        { text: "Texto escrito", value: 1 },
        { text: "Imagen", value: 2 },
        { text: "Audio", value: 3 },
        { text: "Carta", value: 4 },
        { text: "Noticia", value: 5 },
      ],
      exercise_types: [
        { text: "Alternativas", value: 1 },
        { text: "Desarrollo", value: 2 },
        { text: "Audio", value: 3 },
        { text: "Imágenes seleccionables", value: 4 },
      ],
      listenIcon: require("../../assets/img/eureka_icons/icono_escuchar.svg"),
      repeatIcon: require("../../assets/img/eureka_icons/icono_repetiraudio.svg"),
      resumeIcon: require("../../assets/img/eureka_icons/icono_comenzar.svg"),
      pauseIcon: require("../../assets/img/eureka_icons/icono_reproduciendo.svg"),
      muteAll: -1,
      editingName: false,
      uploaded: [],
      pondObject: null,
      disableSave: false,
      customBG: "",
      material: {
        name: "Agregar título del material",
        pages: [],
        duration: 60,
        style: 0,
        description: "Agrega una descripcion",
        profession: null,
        topic: null,
        subject: null,
        grade: null,
        school: this.$store.state.login.school,
        created_by: this.$store.state.login.user_id,
      },
      showPreview: false,
      disablePreview: true,
      incorrectText: "",
      correctText: "",
      correctForm: false,
      incorrectForm: false,
      imageHolder: {},
      audioHolder: {},
      correctAnswer: "",
      nowPlaying: "",
      selectedPage: null,
      tagQuestion: { subtags: [] },
      questions: [],
      editingFilters: true,
      pageToDelete: null,
      applications: 0,
      autoplay: false,
      deletedPageImages: [],
      // deletedQuestions:[],
      topics: [
        { text: "Elige un Tema", value: null },
        { text: "Aplicación evaluativa", value: "Aplicación evaluativa" },
      ],
      subjects: [],
      grades: [],
      roles: [],
      select_duration: [
        { text: "Definir duración", value: 0 },
        { text: "5 minutos", value: 5 },
        { text: "10 minutos", value: 10 },
        { text: "15 minutos", value: 15 },
        { text: "20 minutos", value: 15 },
        { text: "25 minutos", value: 25 },
        { text: "30 minutos", value: 30 },
        { text: "35 minutos", value: 35 },
      ],
      deletedPageAudio: [],
      pushedFamilies: [],
      ejes: [],
      loadingMaterial: false,
      deletedBackground: false,
    };
  },
  computed: {
    isLanguage() {
      return (
        this.$store.state.chatbot.conversation.subject.answer.name ===
        "Lenguaje"
      );
    },
    showSave() {
      return !this.material.id || this.changed;
    },
  },
  mounted() {
    // if (this.materialID) {
    //   this.loadingMaterial = true;
    //   customMaterialService.getMaterialByID(this.materialID).then((data) => {
    //     this.material = data.material;
    //     customMaterialService
    //       .getMaterialApplications(this.material.id)
    //       .then((data) => {
    //         this.applications = data.length;
    //       });
    //     if (this.material.subject && this.material.grade) {
    //       informationService
    //         .getEjesValues(this.material.subject, this.material.grade)
    //         .then((data) => {
    //           this.ejes = data.ejes;
    //           this.loadingMaterial = false;
    //         });
    //     }
    //   });
    // } else {
    //   this.material.pages.push(this.getEmptyPage());
    // }
    this.material.pages.push(this.getEmptyPage());

    // view in the store the answers for oas and for indicators to create the questions
    // if optionalCheckbox is false, the questions will be created based on oas and if it is true, they will be created based on indicators
    const questionsToCreate = [];
    if (!this.$store.state.chatbot.conversation.oa.optionalCheckbox) {
      // iterate in answers and create the list of questions to create
      const answers = this.$store.state.chatbot.conversation.oa.answers;
      answers.forEach((answer) => {
        for (let i = 0; i < answer.count; i++) {
          const questionPayload = {
            type: 0,
            age: 4,
            difficulty: "media",
            oa: {
              id: answer.id,
              name: answer.full_name,
              description: answer.description,
            },
          };
          questionsToCreate.push(questionPayload);
        }
      });
    } else {
      // iterate in indicators and create the list of questions to create
      // now it contains both oa and indicator data for question generation
      // since both are useful for prompting.
      const oas = this.$store.state.chatbot.conversation.indicadores.answers;
      oas.forEach((oa) => {
        oa.options.forEach((option) => {
          for (let i = 0; i < option.count; i++) {
            const questionPayload = {
              type: 0,
              age: this.$store.state.chatbot.conversation.grade.answer.code,
              difficulty: "media",
              oa: {
                id: oa.id,
                name: oa.name,
                description: oa.description,
              },
              subtag: {
                id: option.id,
                description: option.description,
              },
            };
            questionsToCreate.push(questionPayload);
          }
        });
      });
    }
    // TODO: IMPLEMENT THE CREATION OF QUESTIONS FROM THE ARRAY
    this.getQuestions(questionsToCreate);
    this.getOptions();
    this.changed = false;
    // customMaterialService.getSchoolQuestions(this.$store.state.login.school).then(
    //     data => this.questions = data
    // ).catch(()=>this.$toasted.error('Ocurrió un problema al traer tus ejercicios'))
  },
  methods: {
    restoreMaterial() {
      customMaterialService
        .restoreMaterial({ id: this.material.id })
        .then((data) => {
          this.$toasted.success(data.message);
          this.$emit("back-to-list");
        });
    },
    checkIfClose() {
      if (this.material.subject && this.material.grade) {
        this.material.pages = [this.getEmptyPage()];
        this.showOptions = false;
        informationService
          .getEjesValues(this.material.subject, this.material.grade)
          .then((data) => {
            this.ejes = data.ejes;
          });
      }
    },
    createApplication() {
      this.$emit("create-application", this.material.id);
    },
    openConfirmation(pIndex) {
      this.pageToDelete = pIndex;
      this.$refs["delete-page-confirmation"].show();
    },
    getOptions() {
      informationService.getCardSubjectsValues().then((data) => {
        this.subjects = data;
      });
      informationService.getPieGrades().then((data) => {
        this.grades = data.grades;
      }),
        informationService.getMaterialsRoles().then((data) => {
          this.roles = data.roles;
          this.material.profession = 6;
          // this.roles.forEach((role) => {
          //   if (data.chosen === role[0] && !this.material.id) {
          //     this.material.profession = role[0];
          //     this.changed = false;
          //   }
          // });
          this.$watch(
            "material",
            (newV, oldV) => {
              // if (oldVal.id) {
              this.changed = true;
              // }
              if (newV.id && !oldV.id) {
                this.changed = false;
              }
            },
            { deep: true }
          );
          this.changed = false;
        });
    },
    applyFilters() {
      this.editingFilters = false;
    },
    editFilters() {
      this.editingFilters = true;
    },
    attachImagePage(obj) {
      this.pondObject = obj;
      this.$refs["pond-modal-pages"].show();
    },
    closePondPages() {
      this.uploaded = [];
      this.$refs["pond-modal-pages"].hide();
    },
    associateImagePages() {
      let images = this.$refs["pond-pages"].getFiles();
      if (!this.pondObject) {
        return;
      }
      if (images.length < 1 && !this.pondObject.image) {
        this.$toasted.error("Debes subir una imagen para poder guardar");
        return;
      }
      if (this.pondObject.image && images.length === 0) {
        this.pondObject.image = null;
        return;
      }
      this.pondObject["images"].push({
        image: window.URL.createObjectURL(images[0].file),
        image_blob: images[0],
      });
      this.closePondPages();
    },
    openTextModalPage(page, text, eje) {
      this.selectedPage = page;
      this.$refs["text-manager"].openFromPage(page, text, eje);
    },
    manageRecording(obj) {
      // if(obj.audio instanceof Blob){
      //   obj.audio_blob = obj.audio
      //   obj.audio = window.URL.createObjectURL(obj.audio)
      // }
      this.$refs["audio-modal"].open(obj);
    },
    deleteAudio(obj) {
      obj.audio = null;
      obj.audio_blob = null;
      this.deletedPageAudio.push({
        page: obj.id,
      });
    },
    getAssistedMaterialPage() {
      return {
        index: this.material.pages.length,
        questions: [],
        text: this.$store.state.chatbot.conversation.validate_text.createdText,
        images: [],
        audio: null,
        additional_instruction: "",
        eje: null,
      };
    },
    getQuestions(questionsPayload) {
      questionsPayload.forEach((questionPayload, qindex) => {
        this.getQuestion(questionPayload, qindex);
      });
    },
    getQuestion(payload, qindex) {
      const context = this;
      const { type, age, difficulty, oa, subtag } = payload;
      // pre populate the question with the text
      let init_question = {
              kind: 0,
              question: "",
              subtags: [],
              alternatives: [],
              loading: true,
            };
      context.material.pages[0].questions[qindex] = init_question;
      if (this.isLanguage) {
        assistedMaterialService
          .generateQuestion(
            this.$store.state.chatbot.conversation.validate_text.createdText
              .content,
            {
              type: type,
              age: age,
              difficulty: difficulty,
              oa: oa
                ? {
                    id: oa.id,
                    description: oa.description,
                  }
                : null,
              subtag: subtag
                ? {
                    id: subtag.id,
                    description: subtag.description,
                  }
                : null,
            },
            this.$store.state.login.school
          )
          .then((response) => {
            console.log("response", response);
            context.$set(
              context.material.pages[0].questions,
              qindex,
              response
            );
            console.log("question", context.material.pages[0].questions[qindex]);
          });
      } else {
        assistedMaterialService
          .generateMathQuestion(
            {
              type: type,
              age: age,
              difficulty: difficulty,
              oa: oa
                ? {
                    id: oa.id,
                    name: oa.name,
                    description: oa.description,
                  }
                : null,
              subtag: subtag
                ? {
                    id: subtag.id,
                    description: subtag.description,
                  }
                : null,
            },
            this.$store.state.login.school
          )
          .then((response) => {
            console.log("response", response);
            if (!response.body) {
              return;
            }
        

            const page = context.material.pages[0];
            const reader = response.body.getReader();
            const decoder = new TextDecoder();
            function processChunk({ done, value }) {
              if (value) {
                let decoded = decoder.decode(value);
                console.log("decoded", decoded);
                if (decoded.includes("[complete:")) {
                  let stringWithoutBrackets = decoded.slice(10, -1);
                  const q = JSON.parse(stringWithoutBrackets);

                  context.$set(context.material.pages[0].questions, qindex, q);
                } else {
                  let splitDecoded = decoded.split(";");
                  let chunks = splitDecoded.slice(0, -1);
                  chunks.forEach((item) => {
                    if (item.includes("ST ")) {
                      let text = item.slice(3);
                      page.questions[qindex].question += text;
                    } else if (item.includes("ALT ")) {
                      let text = item.slice(4);
                      if (text.includes("A)")) {
                        text = text.slice(3);
                        page.questions[qindex].alternatives[0].text += text;
                      } else if (text.includes("B)")) {
                        text = text.slice(3);
                        page.questions[qindex].alternatives[1].text += text;
                      } else if (text.includes("C)")) {
                        text = text.slice(3);
                        page.questions[qindex].alternatives[2].text += text;
                      } else {
                        text = text.slice(3);
                        page.questions[qindex].alternatives[3].text += text;
                      }
                    }
                  });
                }
              }
              if (done) {
                console.log("done");
                return;
              }
              return reader.read().then(processChunk);
            }
            reader.read().then(processChunk);
          });
      }
    },
    deleteQuestionImage(question) {
      assistedMaterialService.deleteQuestionImage(question).then(() => {
        question.image = null;
      });
    },
    regenQuestionImage(question) {
      assistedMaterialService.regenQuestionImage(question).then((response) => {
        question.image = response.question.image;
      });
    },
    getEmptyPage() {
      return {
        index: this.material.pages.length,
        questions: [],
        text: this.isLanguage
          ? this.$store.state.chatbot.conversation.validate_text.createdText
          : null,
        images: [],
        audio: null,
        additional_instruction: "",
        eje: null,
      };
    },
    movePageUp(pindex) {
      if (pindex > 0) {
        this.material.pages.splice(
          pindex - 1,
          2,
          this.material.pages[pindex],
          this.material.pages[pindex - 1]
        );
      }
    },
    movePageDown(pindex) {
      if (pindex < this.material.pages.length - 1) {
        this.material.pages.splice(
          pindex,
          2,
          this.material.pages[pindex + 1],
          this.material.pages[pindex]
        );
      }
    },
    openQuestionListModalPage(page, text, eje) {
      console.log(eje);
      this.selectedPage = page;
      if (text) {
        this.$refs["question-manager"].questionList(
          page,
          this.material.subject,
          this.material.grade,
          text.id,
          eje
        );
      } else {
        this.$refs["question-manager"].questionList(
          page,
          this.material.subject,
          this.material.grade,
          "",
          eje
        );
      }
    },
    openNewQuestion(pindex) {
      this.$refs["question-manager"].createFromPage(
        pindex,
        this.material.subject,
        this.material.grade
      );
    },
    editQuestion(question, pindex, qindex) {
      this.$refs["question-manager"].editFromPage(question, pindex, qindex);
    },
    pushQuestion(question) {
      this.questions.unshift(question);
    },
    pushQuestionMaterial(question, page_idx) {
      this.pushedFamilies.push(question.family);
      this.material.pages[page_idx].questions.push(question);
    },
    replaceQuestionMaterial(question, page_idx, question_idx) {
      this.material.pages[page_idx].questions.splice(
        question_idx,
        1,
        JSON.parse(JSON.stringify(question))
      );
    },
    pushTextMaterial(text, page_idx) {
      this.material.pages[page_idx].text = text;
    },
    deletePage(pindex) {
      if (!pindex && this.pageToDelete !== null) {
        this.material.pages.splice(this.pageToDelete, 1);
        this.pageToDelete = null;
        this.$refs["delete-page-confirmation"].hide();
      } else {
        this.material.pages.splice(pindex, 1);
      }
    },
    deleteQuestion(pindex, qindex) {
      let page = this.material.pages[pindex];
      let familyID = page.questions[qindex].family;
      let pushedIndex = this.pushedFamilies.indexOf(familyID);
      this.pushedFamilies.splice(pushedIndex, 1);
      page.questions.splice(qindex, 1);
    },
    selectQuestion(question) {
      console.log(question);
      this.$refs["question-manager"].selectQuestion(
        question,
        true,
        this.selectedPage
      );
    },
    moveUp(pindex, idx) {
      if (idx > 0) {
        this.material.pages[pindex].questions.splice(
          idx - 1,
          2,
          this.material.pages[pindex].questions[idx],
          this.material.pages[pindex].questions[idx - 1]
        );
      }
    },
    moveDown(pindex, idx) {
      if (idx < this.material.pages[pindex].questions.length - 1) {
        this.material.pages[pindex].questions.splice(
          idx,
          2,
          this.material.pages[pindex].questions[idx + 1],
          this.material.pages[pindex].questions[idx]
        );
      }
    },
    togglePlayer(name) {
      console.log(this.$refs[`player-${name}`][0]["play"]());
      if (this.nowPlaying) {
        this.$refs[`player-${this.nowPlaying}`][0]["currentTime"] = 0;
      }
      this.nowPlaying = name;
      if (
        this.$refs[`player-${name}`] &&
        this.$refs[`player-${name}`][0]["paused"]
      ) {
        this.$refs[`player-${name}`][0]["play"]();
      } else {
        this.$refs[`player-${name}`][0]["currentTime"] = 0;
      }
    },
    pausePlayer(name) {
      console.log(this.$refs[`player-${name}`]);
      if (
        this.$refs[`player-${name}`] &&
        this.$refs[`player-${name}`][0]["paused"]
      ) {
        this.$refs[`player-${name}`][0]["play"]();
      } else {
        this.$refs[`player-${name}`][0]["pause"]();
      }
    },
    sendFiles(pageMultimedia) {
      pageMultimedia.forEach((p) => {
        let formData = new FormData();
        if (p.image) {
          console.log("imagen");
          formData.append("image", p.image);
        } else if (p.image_id) {
          formData.append("image_id", p.image_id);
        } else if (p.audio) {
          console.log("audio");
          formData.append(
            "audio",
            p.audio,
            `audio-${this.$moment().unix()}.wav`
          );
        } else {
          return;
        }
        formData.append("page", this.material.pages[p.page]["id"]);
        customMaterialService
          .uploadPageFiles(formData)
          .then((data) => console.log(data));
      });
    },
    backToList() {
      if (this.changed) {
        this.$refs["warning-modal"].show();
      } else {
        this.$emit("back-to-list");
      }
    },
    save() {
      let pagesMultimedia = [];
      let backgroundImage = "";
      if (!this.material.profession) {
        this.$toasted.error("Debe seleccionar una profesión!");
        return;
      }
      if (!this.material.grade) {
        this.$toasted.error("Debe seleccionar un curso!");
        return;
      }
      if (!this.material.subject) {
        this.$toasted.error("Debe seleccionar una asignatura!");
        return;
      }
      this.$refs["loading-modal"].show();

      if (this.material.background_image) {
        if (this.material.background_image instanceof Blob) {
          backgroundImage = this.material.background_image;
        } else {
          var xhr = new XMLHttpRequest();
          xhr.open("GET", this.material.background_image);
          xhr.responseType = "blob";
          xhr.onload = function () {
            backgroundImage = xhr.response;
          };
          xhr.send();
          backgroundImage = this.material.background_image;
        }
        delete this.material.background_image;
      }
      this.material.pages.forEach((page, pidx) => {
        page.images.forEach((image) => {
          if (image.image_blob) {
            pagesMultimedia.push({ page: pidx, image: image.image_blob.file });
          } else if (image.image instanceof Blob) {
            pagesMultimedia.push({ page: pidx, image: image.image.file });
          } else if (image.id && this.applications > 0) {
            var xhr = new XMLHttpRequest();
            xhr.open("GET", image.image);
            xhr.responseType = "blob";
            xhr.onload = function () {
              pagesMultimedia.push({ page: pidx, image: xhr.response });
            };
            xhr.send();
          }
        });

        if (page.audio) {
          if (page.audio_blob) {
            pagesMultimedia.push({ page: pidx, audio: page.audio_blob });
          } else if (page.audio instanceof Blob) {
            pagesMultimedia.push({ page: pidx, audio: page.audio });
          } else {
            var xhr = new XMLHttpRequest();
            xhr.open("GET", page.audio);
            xhr.responseType = "blob";
            xhr.onload = function () {
              pagesMultimedia.push({ page: pidx, audio: xhr.response });
            };
            xhr.send();
          }
          page.audio = null;
          delete page.audio_blob;
          delete page.changed;
        }
        delete page.audio;
        page.images = [];
      });

      if (this.mode === "creating") {
        console.log("creating");
        if (this.mode === "editing") {
          delete this.material.id;
          this.material.pages.forEach((page) => {
            delete page.id;
          });
        }
        customMaterialService.createMaterial(this.material).then((data) => {
          // this.material = data;
          this.sendFiles(pagesMultimedia);
          // this.$emit("back-to-list");
          this.$refs["loading-modal"].hide();
          this.$refs["save-material-modal"].close();
          this.$toasted.success("Material guardado exitosamente!");
          this.changed = false;
          if (backgroundImage) {
            this.uploadBackground(data.id, backgroundImage);
          }
          this.$emit("open-created-material", data.id);
        });
      } else {
        customMaterialService.updateMaterial(this.material).then((data) => {
          // this.material = data;
          this.sendFiles(pagesMultimedia);
          this.deleteImages(this.deletedPageImages);
          this.deleteAudios(this.deletedPageAudio);
          if (this.deletedBackground) {
            customMaterialService.deleteMaterialBackground(data.id);
          }
          // this.$emit("back-to-list");
          this.$refs["loading-modal"].hide();
          this.$refs["save-material-modal"].close();
          this.$toasted.success("Material guardado exitosamente!");
          this.changed = false;
          if (backgroundImage) {
            this.uploadBackground(data.id, backgroundImage);
          }
          this.$emit("open-created-material", data.id);
        });
      }
    },
    deleteImages(deletedImages) {
      deletedImages.forEach((imgPage) => {
        customMaterialService
          .deletePageImage(imgPage)
          .then((response) => console.log(response))
          .catch(() => this.$toasted.error("No se pudo eliminar imagen"));
      });
    },
    deleteAudios(deletedAudios) {
      deletedAudios.forEach((page) => {
        customMaterialService
          .deletePageAudio(page)
          .then((response) => console.log(response))
          .catch(() => this.$toasted.error("No se pudo eliminar Audio"));
      });
    },
    preview() {
      this.showPreview = !this.showPreview;
    },
    detectLatex(text) {
      const latexRegex = /\\[a-zA-Z]+(?:\{(?:[^{}]|(?:\{[^{}]*\}))*\})?|\$(?:[^$]|\\\$)*\$/g;
      return latexRegex.test(text);
    },
  },
};
</script>

<style lang="scss">
.custom-material {
  background-color: #f8b500;
  border-radius: 25px;
  padding: 1em;
  margin: 1em 0 1em 0;
  width: 100%;
  /* border: #F8B500 1px solid;
  border-radius: 25px;
  padding-bottom: 5em !important; */
  padding: 0.5em;
  margin: auto !important;
}

.page-form {
  width: 100%;
  /* border: #F8B500 1px solid;
  border-radius: 25px;*/
  padding-top: 1em !important;
  margin: auto !important;
}

.circle {
  background: #ffffff;
  border-radius: 25px;
  border: 2px solid #e84855;
  color: #e84855;
  height: 25px;
  font-weight: bold;
  width: 25px;
  display: table;
  margin: 0px 6px 0px 2px;
  text-align: center;
  padding: 1px;
}
.circle p {
  vertical-align: middle;
  display: table-cell;
  font-size: 0.9em;
}
.scissors {
  height: 30px; /* image height */
  width: 100%;
  margin: auto auto;
  background-size: 30px;
  background-image: url("http://i.stack.imgur.com/cXciH.png");
  background-repeat: no-repeat;
  background-position: right;
  position: relative;
  overflow: hidden;
}
.scissors:after {
  content: "";
  position: relative;
  top: 50%;
  display: block;
  border-top: 1px dashed rgb(122, 119, 119);
  margin-top: -1px;
  transform: scale(4);
}
.alternative {
  background-color: #ffffff;
  margin: 1em 0 1em 0;
  border-radius: 15px;
  padding: 0.5rem;
  min-height: 3em;
  width: 60%;
  border: #e84855 2px solid;
  p {
    margin-bottom: 0;
  }
}

.add-question-div {
  background-color: rgb(243, 243, 243);
  height: 80px;
  vertical-align: middle;
  padding: 25px 10px 25px 10px;
  border-radius: 5px;
}
.essay_question {
  background-color: #fff;
  border-radius: 5px;
  width: 100%;
  height: 80px;
  border: #e84855 2px solid;
  // padding: 10px 10px 25px 10px;
}
.dictation_question {
  background-color: rgb(243, 243, 243);
  border-radius: 5px;
  height: 50px;
  padding: 10px 10px 25px 10px;
}
.question {
  width: 100%;
}

.alt_correct {
  color: #ffffff;
  background-color: #e48692;
}

.alt_incorrect {
  color: #000000;
  background-color: #ffffff;
}

.correct-option {
  background-color: rgb(243, 243, 243);
  margin: 1em 0 1em 0;
  border-radius: 5px;
  padding: 1em;
  min-height: 3em;
  max-height: 1000px;
  border-color: rgb(105, 226, 105);
  border-style: dashed;
  border-width: thin;
}
.incorrect-option {
  background-color: rgb(243, 243, 243);
  margin: 1em 0 1em 0;
  border-radius: 5px;
  padding: 1em;
  min-height: 3em;
  max-height: 1000px;
  border-color: rgb(236, 65, 65);
  border-style: dashed;
  border-width: thin;
}

.options {
  margin: 0 2px 0 2px;
}
.image-section {
  width: 60%;
  max-width: 75%;
  align-items: center;
}
.audio-section {
  width: 60%;
  max-width: 75%;
}

.custom-page {
  background-color: #f3f3f3;
  border: #e84855 2px dashed;
  border-radius: 5px;
  padding: 1em;
  margin: 1em 0 1em 0;
  width: 100%;
  /* border: #F8B500 1px solid;
  border-radius: 25px;
  padding-bottom: 5em !important; */
  padding: 0.5em;
  margin: auto !important;
}
.page-trash {
  margin-right: -30px;
}
.custom-number-input {
  width: 50% !important;
  input {
    background-color: #f9b60a !important;
    color: #ffffff !important;
    width: 70% !important;
  }
}
.tag-badge {
  background-color: #f9b60a !important;
  color: #ffffff !important;
  font-size: 0.8em;
  border-radius: 5px;
  padding: 0.2em 0.5em 0.2em 0.5em;
}

.custom-button {
  background-color: #f9b60a;
  border-radius: 6px;
  padding: 0.2em 1em;
  border: 1px solid #f9b60a;
  cursor: pointer;
  &:hover {
    background-color: #f9b60a80;
  }
}

.main-carousel {
  width: 100% !important;
  height: 100%;
}

.image-container {
  position: relative;
  display: block;
}

.floating-button-container {
  position: absolute;
  top: -10px;
  right: -25px;
  display: none;
  flex-direction: row;
}

.floating-button-container b-button {
  margin-bottom: 5px;
  padding: 5px;
  border: none;
  cursor: pointer;
}

.image-container:hover .floating-button-container {
  display: flex;
}
</style>
